import ProfessoresOneLayout from "./professores";
import BackToTop from "./backToTop";
import { PrimeReactProvider } from "primereact/api";

export default function Home() {
  return (
    <>
      <PrimeReactProvider>
        <main className="">
          <ProfessoresOneLayout />
          <BackToTop />
        </main>
      </PrimeReactProvider>
    </>
  );
}
